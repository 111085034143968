<template>
  <div class="My360Application">
    <!-- 360考核申请 -->
    <van-nav-bar :title="title"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
      <template #right>
        <div v-if="is360 != 'true'">{{ lastscore }}{{$t('jxApprovalTagApply.atTxt1')}}</div>
      </template>
    </van-nav-bar>
    <div class="content">
      <van-pull-refresh v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  :finished-text="$t('module.noMore')"
                  @load="onLoadAwait">
          <div class="zb_group"
               v-for="(item, index) in assessList"
               :key="index"
               @click="goToDetails(item)">
            <van-cell-group>
              <van-cell class="top_cell"
                        center
                        :title="is360 == 'true' ? item.indicatorname : item.itemname"
                        :value="is360 == 'true' ? item.typename : item.itemtype" />
              <van-cell class="btm_cell"
                        center>
                <template #title>
                  <span v-if="is360 == 'true'"
                        class="custom-title">{{
                    item.indicatordesp
                  }}</span>
                  <span v-else
                        class="custom-title">{{ item.itemdesc }}</span>
                </template>
                <template #label>
                  <span v-if="is360 == 'true'"
                        class="custom-score">{{
                    item.score
                  }}</span>
                  <span v-else
                        class="custom-score">{{ item.tgvalue }}</span>
                  {{$t('jxApprovalTagApply.atTxt1')}}
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="footer">
      <div class="f_left"
           @click="unAgreeItem">{{$t('jxApprovalTagApply.atTxt2')}}</div>
      <div class="f_right"
           @click="agreeItem">{{$t('jxApprovalTagApply.atTxt3')}}</div>
    </div>
    <van-dialog v-model="showDia"
                :title="$t('jxApprovalTagApply.atTxt2')"
                show-cancel-button
                :before-close="closeDia">
      <div style="padding: 10px">
        <van-cell class="desc_cell"
                  :value="$t('jxApprovalTagApply.atTxt4')" />
        <van-field class="desc_field"
                   v-model="checkmemo"
                   rows="3"
                   autosize
                   type="textarea"
                   :placeholder="$t('jxApprovalTagApply.atTxt4')" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import {
  getMy360IndicatorList,
  getMyKpiIndicatorList,
  agreeMy360KhIndicator,
  agreeMyKpiKhIndicator,
} from "@api/wxjx.js";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    let title = this.$route.query.title;
    let is360 = this.$route.query.is360;
    let mautoid = this.$route.query.mautoid;
    let lastscore = this.$route.query.lastscore;
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      title,
      is360,
      mautoid,
      lastscore,
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      ischeck: 1, // 1同意，0不同意
      checkmemo: "", // 不同意的原因
      showDia: false,
      assessList: [],
      is_zjp: this.$route.query.is_zjp, // 是否显示直接评价人
      is_jjp: this.$route.query.is_jjp, // 是否显示间接评价人
    };
  },
  created () { },
  methods: {
    onClickLeft () {
      this.$router.push({
        path: "/jxApprovalTag",
        query: { is360: this.is360 },
      });
    },
    // 查看某个“360指标方案”所有指标明细
    getMy360Indicator () {
      let _this = this;
      getMy360IndicatorList({
        empid: this.userInfo.empid,
        mautoid: this.mautoid,
      }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    // 查看某个“KPI指标方案”所有指标明细接口
    getMyKpiIndicator () {
      let _this = this;
      getMyKpiIndicatorList({
        empid: this.userInfo.empid,
        mautoid: this.mautoid,
      }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    onLoadAwait () {
      // 异步更新数据
      if (this.isLoadingAwait)
      {
        this.assessList = [];
        this.isLoadingAwait = false;
      }
      if (this.is360 == true || this.is360 == "true")
      {
        this.getMy360Indicator();
      } else
      {
        this.getMyKpiIndicator();
      }
    },
    onRefreshAwait () {
      this.assessList = [];
      this.finishedAwait = false;
      this.loadingAwait = true;
      this.onLoadAwait();
      Toast(this.$t('module.refreshSuccessfully'));
    },
    // 360同意/不同意
    agreeMy360 (ischeck, checkmemo) {
      agreeMy360KhIndicator({
        mautoid: this.mautoid,
        ischeck: ischeck,
        checkmemo: checkmemo,
        username: this.userInfo.username,
      }).then((res) => {
        if (res.iserror == 0)
        {
          Toast.success(this.$t('jxApprovalTagApply.atTxt5'));
          this.onClickLeft();
        }
      });
    },
    // KPI同意/不同意
    agreeMyKpi (ischeck, checkmemo) {
      agreeMyKpiKhIndicator({
        mautoid: this.mautoid,
        ischeck: ischeck,
        checkmemo: checkmemo,
        username: this.userInfo.username,
      }).then((res) => {
        if (res.iserror == 0)
        {
          Toast.success(this.$t('jxApprovalTagApply.atTxt5'));
          this.onClickLeft();
        }
      });
    },
    // 同意按钮
    agreeItem () {
      if (this.assessList.length == 0)
      {
        Toast.fail(this.$t('jxApprovalTagApply.atTxt6'));
        return false;
      }
      Dialog.confirm({
        title: this.$t('jxApprovalTagApply.atTxt3'),
        message: this.$t('jxApprovalTagApply.atTxt7'),
      })
        .then(() => {
          if (this.is360 == true || this.is360 == "true")
          {
            this.agreeMy360(1, "");
          } else
          {
            this.agreeMyKpi(1, "");
          }
        })
        .catch(() => { });
    },
    // 不同意按钮
    unAgreeItem () {
      // this.$router.push('/jxMy360TargetDetails')
      if (this.assessList.length == 0)
      {
        Toast.fail(this.$t('jxApprovalTagApply.atTxt6'));
        return false;
      }
      this.showDia = true;
    },
    // 弹窗关闭回调
    closeDia (action, done) {
      if (action == "confirm")
      {
        if (this.checkmemo)
        {
          if (this.is360 == true || this.is360 == "true")
          {
            this.agreeMy360(0, this.checkmemo);
          } else
          {
            this.agreeMyKpi(0, this.checkmemo);
          }
          done();
        } else
        {
          Toast.fail(this.$t('jxApprovalTagApply.atTxt8'));
          done(false);
        }
      } else
      {
        done();
      }
    },
    //
    goToDetails (item) {
      const detaId = item.autoid;
      this.$router.push({
        path: "/jxApprovalTagDetails",
        query: {
          parentTitle: this.title,
          is360: this.is360,
          mautoid: this.mautoid,
          detaId: detaId,
          lastscore: this.lastscore,
          is_zjp: this.is_zjp, // 是否显示直接评价人
          is_jjp: this.is_jjp // 是否显示间接评价人
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.My360Application {
  height: 100vh;
  overflow: hidden;
  /deep/.van-nav-bar__right {
    color: #fff;
    font-size: 36px;
    font-family: Source Han Sans CN;
  }
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .zb_group {
      // display: flex;
      border-left: 6px solid #2b8df0;
      margin: 30px 0;
      .van-cell-group {
        .top_cell {
          .van-cell__title {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2b8df0;
          }
          .van-cell__value {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .btm_cell {
          .van-cell__title {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .custom-title {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .van-cell__label {
            text-align: right;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .custom-score {
              font-size: 48px;
              font-family: DIN;
              font-weight: bold;
              color: #2b8df0;
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .f_left {
      width: 40%;
      text-align: center;
      border-right: 2px solid #cccccc;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #f53838;
    }
    .f_right {
      width: 40%;
      text-align: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #2b8df0;
    }
  }
}
</style>